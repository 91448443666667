<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Servizi"
    :route-father="routeFather"
  >
    <template v-slot:header-actions>
      <ExportExcelTable
        :worksheetName="worksheetName"
        :fileName="fileName"
        :fieldsToExport="fieldsToExport"
        :fetch="fetchExcel"
      > </ExportExcelTable>
      <v-btn text link @click="goToNew" :disabled="isLicenseBlocked">
        <v-icon class="mr-2">mdi-plus</v-icon> Nuovo
      </v-btn>
    </template>
    <template v-slot:toolbar-extension>

    </template>
    <template v-slot:content>
      <ServicesTable
        table-height="calc(100vh - 200px)"
        :filters.sync="filtersValue"
        @service-detail="handleEdit"
        @service-double-click="handleEdit"
        :bus="bus"
      ></ServicesTable>
    </template>
  </FullScreenDialog >
</template>

<script>
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import servicesService from '@/services/services/services.service.js'
import ExportExcelTable from "@/components/common/ExportExcelTable.vue";
import dateUtils from "@/mixins/common/dateUtils"
import ServicesTable from '@/components/common/registry/ServicesTable.vue'
import Vue from 'vue'

export default {
  name: "ServicesRegistryList",
  components: {
    FullScreenDialog,
    TypeDataTable,
    ExportExcelTable,
    ServicesTable
  },
  mixins: [dateUtils],
  data: function() {
    let props = this.$router.resolve({name: 'Registries'});

    return {
      dialog: true,
      routeFather: props.href,
      dataTableHeightValue: 400,
      filtersValue: [],
      worksheetName: "",
      fileName:"",
      fieldsToExport: {},
      bus: new Vue(),
      isLicenseBlocked: undefined
    }
  },
  props: {
    filters: {
      type: Array,
      default: () => []
    }
  },
  mounted: function() {
    this.filtersValue = this.filters;
    this.fieldsToExport = servicesService._fieldsToExport();
    this.worksheetName = "Export Servizi ";
    this.fileName =
      "Servizi_" + this._buildDateToExport(new Date()) +
      ".xls";
  },
  methods: {
    handleEdit(service) {
      this.$router.push({
        name: 'ServicesRegistryEditForm',
        params: {
          id: service.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'ServicesRegistryList'}).href,
          pathName: 'ServicesRegistryList'
        }
      })
    },
    goToNew() {
      this.$router.push({
        name: 'ServicesRegistryNewForm',
        query: {
          pathToGoBack: this.$router.resolve({name: 'ServicesRegistryList'}).href
        }
      })
    },
    async fetchExcel(){
      let filters = [...this.filters];

      let services = (await servicesService.list(undefined, 15000, filters)).rows

      return services
    },
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
}
</script>